<template>
  <div>
    <ion-grid class="ion-no-padding">
      <ion-list mode="ios" lines="none">
        <div class="title">{{ $t('newcustomer.person_in_charge') }}</div>
        <div class="form-person" v-for="(data, index) in customerPicData" :key="index">
          <ion-text :class="data.position === 'Key Contact' ? 'text-primary' : ''">{{
            data.position || 'sub-buyer'
          }}</ion-text>
          <span>
            <ion-text>{{ `${data.first_name}${data.last_name ? ' ' + data.last_name : ''}` }}</ion-text>
            <ion-chip v-if="enablePicConnectByPin || data?.label === 'you'" style="margin-left: 5px">{{
              data.label
            }}</ion-chip>
          </span>
          <ion-text>{{
            data.phone || data.mobile ? '+' + handleGetMobilePhone(data.phone || data.mobile) : '-'
          }}</ion-text>
          <ion-text>{{ data.email }}</ion-text>
          <ion-text>{{ $t('newcustomer.language') }}: {{ handleGetLanguage(data.language || '') }}</ion-text>

          <ion-text v-if="enablePicConnectByPin && data.isAllowToShowCode" style="margin-top: 10px"
            >{{ $t('invitation_pin') }}: {{ data.code }}</ion-text
          >
          <ion-text v-if="enablePicConnectByPin && data.isAllowToShowCode"
            >{{ $t('valid_until') }}: {{ dayjs(data.valid_to).format('DD MMM YYYY') }}</ion-text
          >
          <ion-chip v-if="data.is_expired && enablePicConnectByPin && data.isAllowToShowCode" color="red">{{
            $t('expired')
          }}</ion-chip>
          <div
            class="form-btn"
            v-if="enablePicConnectByPin && data.isAllowToShowCode"
            style="margin-bottom: 10px"
          >
            <ion-button
              v-show="!data.is_expired"
              :disabled="!data.isAllowToShowCode"
              mode="md"
              size="small"
              fill="outline"
              @click="copyMessage(data.code)"
              >{{ $t('copy_to_clipboard') }}</ion-button
            >
            <ion-button
              v-show="data.is_expired"
              :disabled="!data.isAllowToShowCode"
              mode="md"
              size="small"
              fill="outline"
              @click="sendNewPinAlert(data)"
              >{{ $t('send_new_pin') }}</ion-button
            >
          </div>

          <div class="form-btn" v-if="data.__typename === 'Person' && data.position !== 'Key Contact'">
            <ion-button
              :disabled="checkDisableBtnRemove(data.user_id)"
              mode="md"
              size="small"
              fill="outline"
              @click="handleOpenPopupConfirm(data.id)"
              >{{ $t('accountPage.remove') }}</ion-button
            >
            <ion-button
              :disabled="data.user_id"
              mode="md"
              size="small"
              fill="outline"
              @click="handleEditPersonInCharge(data)"
              >{{ $t('edit') }}</ion-button
            >
          </div>
          <div class="form-btn" v-if="data.__typename === 'ViewSubBuyer'">
            <ion-text>{{ $t('newcustomer.active') }}:</ion-text>
            <ion-toggle
              :checked="data.active"
              @ionChange="handleUpdateSubBuyer(data.id, $event)"
              mode="ios"
              color="primary"
            ></ion-toggle>
          </div>
        </div>
        <div class="btn-submit">
          <ion-button expand="block" class="ion-text-wrap" mode="md" @click="handleOpenModalAddPerson">
            <ion-icon :icon="addOutline" />
            {{ $t('add') }}
          </ion-button>
        </div>
      </ion-list>
    </ion-grid>
    <ion-modal mode="md" :is-open="isOpenModalAddPerson">
      <ion-page>
        <Header
          :title="
            !listEditPersonInCharge
              ? $t('newcustomer.add_person_in_charge')
              : $t('newcustomer.update_person_in_charge')
          "
          @handleCloseModal="setOpenModalAddPerson(false)"
        />
        <ion-content :scroll-events="true" @ionScroll="handleScroll($event)">
          <ModalAddPersonInCharge
            @handleReloadComponent="setOpenModalAddPerson(false), $emit('handleReloadComponent')"
            :customerDetailsEdit="customerDetailsEdit"
            :listEditPersonInCharge="listEditPersonInCharge"
            :isKeyContact="isKeyContact"
            :user="user"
            :originCode="originCode"
          />
        </ion-content>
      </ion-page>
    </ion-modal>

    <ion-loading
      mode="ios"
      :is-open="isOpenRef"
      cssClass="my-custom-class"
      :message="`${$t('please_wait')}. . .`"
      :duration="timeout"
      @didDismiss="setOpen(false)"
    >
    </ion-loading>
  </div>
</template>

<script>
import { apolloClient } from '@/main';
import { deletePersonB2b, resendPicPinCode, updateSubBuyer } from '@/modules/b2b/services/graphql';
import { getDefaultCountryCode } from '@/modules/b2b/services/libs/helper';
import Header from '@/modules/b2b/views/customer/CustomerDetail/newcomponents/HeaderModal.vue';
import { getPicConnectByPinConfig } from '@/services/shared/graphql';
import { picDefaultLanguages } from '@/services/shared/helper/countries';
import { errorAlert } from '@/utils/alert';
import { Clipboard } from '@capacitor/clipboard';
import { Capacitor } from '@capacitor/core';
import { Keyboard } from '@capacitor/keyboard';
import { alertController } from '@ionic/vue';
import dayjs from 'dayjs';
import { addOutline } from 'ionicons/icons';
import debounce from 'lodash.debounce';
import { ref } from 'vue';
import ModalAddPersonInCharge from './ModalAddPersonInCharge.vue';

export default {
  components: {
    Header,
    ModalAddPersonInCharge
  },
  props: ['customerDetailsEdit', 'sortListShowPersonInCharge', 'mobilePhone'],
  inject: ['$storage'],
  setup() {
    const isOpenModalAddPerson = ref(false);
    const setOpenModalAddPerson = async (state) => (isOpenModalAddPerson.value = state);
    const listEditPersonInCharge = ref(null);
    const isOpenRef = ref(false);
    const setOpen = async (state) => (isOpenRef.value = state);
    const user = ref(null);
    const originCode = ref(null);
    const customerPicData = ref([]);
    const handleScroll = (event) => {
      if (event && Capacitor.isNativePlatform()) {
        Keyboard.hide();
      }
    };
    const enablePicConnectByPin = ref(false);
    return {
      user,
      handleScroll,
      isOpenModalAddPerson,
      setOpenModalAddPerson,
      addOutline,
      listEditPersonInCharge,
      isOpenRef,
      setOpen,
      originCode,
      picDefaultLanguages,
      errorAlert,
      dayjs,
      enablePicConnectByPin,
      customerPicData
    };
  },
  computed: {
    isKeyContact() {
      const findKeyContact = this.sortListShowPersonInCharge.find((item) => item.position === 'Key Contact');
      return findKeyContact ? true : false;
    },
    isKeyContactMerchant() {
      return this.isKeyContact && this.sortListShowPersonInCharge[0].phone === this.mobilePhone
        ? true
        : false;
    }
  },
  async mounted() {
    this.user = await this.$storage.getUser();
    this.originCode = this.user.country.name;
    await this.getPicConnectByPinConfig();
    this.loadcustomerPicData();
  },
  methods: {
    checkDisableBtnRemove(params) {
      let isDisable = false;
      if (params > 0 && !this.isKeyContactMerchant) isDisable = true;
      return isDisable;
    },
    handleGetLanguage(params) {
      let language = picDefaultLanguages;
      const findLanguage = language.find((item) => item.key === params);
      if (findLanguage) {
        const data = findLanguage.value;
        return data;
      } else {
        return 'English';
      }
    },
    handleGetMobilePhone(params) {
      if (params) {
        const phone =
          params.indexOf(' ') > -1
            ? params.split(' ')[1]
            : params.length > 8
            ? params.substring(2, params.length)
            : params;
        const countryCode =
          params.indexOf(' ') > -1
            ? params.split(' ')[0]
            : params.length > 8
            ? params.substring(0, 2)
            : getDefaultCountryCode();
        const phoneNumber = countryCode + ' ' + phone;
        return phoneNumber;
      } else {
        return '';
      }
    },
    handleUpdateSubBuyer: debounce(async function (id, params) {
      try {
        await this.setOpen(true);
        await apolloClient.mutate({
          mutation: updateSubBuyer,
          variables: {
            active: params.detail.checked,
            id: id,
            supplierId: this.customerDetailsEdit.id
          }
        });
        this.$emit('handleReloadComponent');
        await this.setOpen(false);
      } catch (e) {
        await this.setOpen(false);
        this.errorAlert(e.message);
      }
    }, 500),
    handleEditPersonInCharge(params) {
      this.listEditPersonInCharge = params;
      this.setOpenModalAddPerson(true);
    },
    handleOpenModalAddPerson() {
      this.listEditPersonInCharge = null;
      this.setOpenModalAddPerson(true);
    },
    async handleOpenPopupConfirm(params) {
      const alert = await alertController.create({
        mode: 'ios',
        cssClass: 'my-custom-class',
        header: this.$t('customerPage.delete_person'),
        message: this.$t('are_you_sure'),
        buttons: [
          {
            text: this.$t('no'),
            role: 'cancel',
            id: 'cancel-button'
          },
          {
            text: this.$t('yes'),
            id: 'confirm-button',
            handler: () => {
              this.handleRemovePerson(params);
            }
          }
        ]
      });
      return alert.present();
    },
    async handleRemovePerson(params) {
      try {
        await this.setOpen(true);
        await apolloClient.mutate({
          mutation: deletePersonB2b,
          variables: {
            customerId: this.customerDetailsEdit.id,
            personId: params
          }
        });
        await this.setOpen(false);
        this.$emit('handleReloadComponent');
      } catch (e) {
        await this.setOpen(false);
        this.errorAlert(e.message);
      }
    },
    async copyMessage(code) {
      await Clipboard.write({ string: code });
    },
    async sendNewPinAlert(data) {
      const userName = data.first_name + (data.last_name ? ' ' + data.last_name : '');
      const alert = await alertController.create({
        mode: 'ios',
        cssClass: 'my-custom-class',
        header: this.$t('send_new_pin'),
        message: this.$t('send_new_pin_message', { user_name: userName, phone_number: data.phone }),
        buttons: [
          {
            text: this.$t('no'),
            role: 'cancel',
            id: 'cancel-button'
          },
          {
            text: this.$t('yes'),
            id: 'confirm-button',
            handler: () => {
              this.resendPicPinCode({ requestId: data.requestConnectId });
            }
          }
        ]
      });
      return alert.present();
    },
    async getPicConnectByPinConfig() {
      try {
        const res = await apolloClient.query({
          query: getPicConnectByPinConfig
        });
        if (res?.data) {
          this.enablePicConnectByPin = res.data.getPicConnectByPinConfig;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async resendPicPinCode(params) {
      try {
        const res = await apolloClient.mutate({
          mutation: resendPicPinCode,
          variables: {
            requestId: params.requestId
          }
        });

        if (res?.data?.resendPicPinCode) {
          this.$emit('handleReloadComponent');
        } else {
          this.errorAlert('Failed to resend pin code');
        }
      } catch (error) {
        console.error(error);
      }
    },
    loadcustomerPicData() {
      const data = this.sortListShowPersonInCharge?.filter((person) => person?.id);

      const REQUEST_CONNECT_STATUS = {
        PENDING: 0,
        ACCEPTED: 1,
        REJECTED: 2
      };

      const updatedData = data.map((person) => {
        let label;

        let replacePersonWithUserData = false;
        let isAllowToShowCode = false;

        const requestConnect = person.requestConnectCustomer.sort((a, b) => b.id - a.id)[0];

        if (requestConnect?.status === REQUEST_CONNECT_STATUS.ACCEPTED && person.User?.id === this.user.id) {
          label = this.$t('you');
          replacePersonWithUserData = true;
        } else if (
          requestConnect?.status === REQUEST_CONNECT_STATUS.ACCEPTED &&
          person.User?.id !== this.user.id &&
          person.User?.active
        ) {
          label = this.$t('user_connected');
        } else if (
          requestConnect?.status === REQUEST_CONNECT_STATUS.ACCEPTED &&
          person.User?.id !== this.user.id &&
          (!person.User?.active || !person.User?.is_deleted)
        ) {
          label = this.$t('user_inactive');
        } else if (requestConnect?.status === REQUEST_CONNECT_STATUS.PENDING) {
          label = this.$t('pending');
          isAllowToShowCode = true;
        } else if (requestConnect?.status === REQUEST_CONNECT_STATUS.REJECTED) {
          label = this.$t('rejected');
        } else if (!person.user_id) {
          label = this.$t('no_user_connected');
        } else {
          label = null;
        }

        return {
          ...person,
          first_name: replacePersonWithUserData ? this.user.first_name : person.first_name,
          last_name: replacePersonWithUserData ? this.user.last_name : person.last_name,
          phone: replacePersonWithUserData ? this.user.mobile : person.phone,
          email: replacePersonWithUserData ? this.user.email : person.email,
          requestConnectId: requestConnect?.id,
          code: requestConnect?.code,
          valid_to: requestConnect?.valid_to,
          label,
          isAllowToShowCode: requestConnect?.code ? isAllowToShowCode : false,
          is_expired: requestConnect?.is_expired
        };
      });

      const findKeyContact = updatedData?.find((item) => item.position === 'Key Contact');

      if (findKeyContact) {
        const key = updatedData.indexOf(findKeyContact);
        updatedData.splice(key, 1);
        updatedData.unshift(findKeyContact);
      }

      this.customerPicData = updatedData;
    }
  }
};
</script>

<style lang="scss" src="./styles/ModalPersonInCharge.scss" scoped></style>
