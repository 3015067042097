<template>
  <div class="company-details" v-if="viewEntered" v-show="!isB2cUser">
    <!-- Add Branch Button -->
    <div class="form-group-btn">
      <ion-button
        expand="block"
        @click="handleAddBranch"
        mode="md"
        color="primary"
        :disabled="disableAddBranch"
        ><ion-icon :icon="addOutline" />{{ $t('newcustomer.add_brand') }}</ion-button
      >
    </div>

    <!-- Companies Card -->
    <div>
      <div class="card" @click="handleOpenModal(customerDetails, false)">
        <ion-card v-if="customerDetails">
          <CustomerDetailImage
            v-if="asyncData"
            :customer_name="customerDetails.name"
            :image="customerDetails.customerImage[0]?.image"
          />
          <ion-card-header>
            <ion-card-subtitle>{{ $t('newcustomer.company_name') }}</ion-card-subtitle>
            <div class="title">
              <ion-card-title>{{ customerDetails.name }}</ion-card-title>
              <ion-img v-if="customerDetails.halal_products" :src="halal"></ion-img>
            </div>
          </ion-card-header>
          <ion-card-content>
            <ion-card-subtitle>{{ $t('newcustomer.profile') }}</ion-card-subtitle>
            <div class="profile-text">{{ customerDetails.profile }}</div>
            <ion-card-subtitle>{{ $t('newcustomer.address') }}</ion-card-subtitle>
            <div class="profile-form" v-for="value in customerDetails.addresses" :key="value.id">
              <div class="profile-text" v-if="value.is_default">{{ getAddressFormat(value) }}</div>
            </div>
            <div class="d-flex flex-row pt-3">
              <div class="w-50">
                <ion-text class="ion-text-uppercase" color="text-medium"
                  ><p class="fs-12 fw-bold">{{ $t('customerPage.account_number') }}</p></ion-text
                >
                <ion-text class="profile-text" color="tertiary"
                  ><p>{{ customerDetails.account_number }}</p></ion-text
                >
              </div>
            </div>
            <div class="branch-form">
              <p>{{ $t('newcustomer.main_brand') }}</p>
            </div>
          </ion-card-content>
        </ion-card>
      </div>
      <div class="card" v-for="(data, index) in customerDetails.branches" :key="index">
        <ion-card @click="handleOpenModal(data, true)">
          <CustomerDetailImage
            v-if="asyncData"
            :customer_name="data.branch"
            :image="data.customerImage[0]?.image"
          />
          <ion-card-header>
            <ion-card-subtitle>{{ $t('newcustomer.company_name') }}</ion-card-subtitle>
            <div class="title">
              <ion-card-title>{{ data.branch }}</ion-card-title>
              <ion-img v-if="data.halal_products" :src="halal"></ion-img>
            </div>
          </ion-card-header>
          <ion-card-content>
            <ion-card-subtitle>{{ $t('newcustomer.profile') }}</ion-card-subtitle>
            <div class="profile-text">{{ data.profile }}</div>
            <ion-card-subtitle>{{ $t('newcustomer.address') }}</ion-card-subtitle>
            <div class="profile-form" v-for="value in data.addresses" :key="value.id">
              <div class="profile-text" v-if="value.is_default">{{ getAddressFormat(value) }}</div>
            </div>
            <div class="d-flex flex-row pt-3">
              <div class="w-50">
                <ion-text class="ion-text-uppercase" color="text-medium"
                  ><p class="fs-12 fw-bold">{{ $t('customerPage.account_number') }}</p></ion-text
                >
                <ion-text class="profile-text" color="tertiary"
                  ><p>{{ data.account_number }}</p></ion-text
                >
              </div>
            </div>
          </ion-card-content>
        </ion-card>
      </div>
    </div>
  </div>

  <!-- Modal Company Detail -->
  <ion-modal mode="md" :is-open="isOpenRef" css-class="my-custom-class" @didDismiss="dismissModal()">
    <ion-page>
      <Header
        :title="customerDetailsEdit?.name || customerDetailsEdit?.branch"
        @handleCloseModal="dismissModal()"
      />
      <ion-content>
        <ModalCustomerDetails
          @closeModal="dismissModal()"
          @handleOpenModalBusiness="setOpenModalBusiness(true)"
          @handleOpenModalEditDeliveryTime="setOpenModalDeliveryTime(true)"
          @handleOpenModalUploadImage="setOpenModalUpdateImage(true)"
          @handleOpenModalPersonInCharge="handleOpenModalPersonInCharge"
          @handleOpenModalPayment="handleOpenModalPayment"
          @handleSendListShowPersonInCharge="handleSendListShowPersonInCharge"
          :mobilePhone="mobilePhone"
          :countryId="countryId"
          :customerDetailsEdit="customerDetailsEdit"
          :isGetListCardWhenBackBtn="isGetListCardWhenBackBtn"
        />
      </ion-content>
    </ion-page>
  </ion-modal>

  <!-- Modal Upload Image -->
  <ion-modal mode="md" :is-open="isOpenModalUpdateImage" @didDismiss="setOpenModalUpdateImage(false)">
    <ion-page>
      <Header
        :title="customerDetailsEdit?.name || customerDetailsEdit?.branch"
        @handleCloseModal="setOpenModalUpdateImage(false)"
      />
      <ion-content>
        <ModalUploadImage
          @handleCloseModalUploadImage="handleRenderComponent"
          @handleSendImageCrop="handleSendImageCrop"
          :image="customerDetailsEdit?.customerImage"
          :customer_id="customerDetailsEdit?.id"
          :imageAfterCrop="imageAfterCrop"
        />
      </ion-content>
    </ion-page>
  </ion-modal>

  <!-- Modal Business Information -->
  <ion-modal mode="md" :is-open="isOpenModalBusiness" @didDismiss="setOpenModalBusiness(false)">
    <ion-page>
      <Header
        :title="customerDetailsEdit?.name || customerDetailsEdit?.branch"
        @handleCloseModal="setOpenModalBusiness(false)"
      />
      <ion-content :scroll-events="true" @ionScroll="handleScroll($event)">
        <ModalEditBusinessInfomation
          :customerDetailsEdit="customerDetailsEdit"
          @handle-rerender-component="handleRenderComponent"
        />
      </ion-content>
    </ion-page>
  </ion-modal>

  <ion-modal mode="md" :is-open="isOpenModalPersonInCharge" @didDismiss="setOpenModalPersonInCharge(false)">
    <ion-page>
      <Header
        :title="customerDetailsEdit?.name || customerDetailsEdit?.branch"
        @handleCloseModal="setOpenModalPersonInCharge(false)"
      />
      <ion-content>
        <ModalPersonInCharge
          :sortListShowPersonInCharge="customerDetailsEdit?.persons"
          :customerDetailsEdit="customerDetailsEdit"
          :mobilePhone="mobilePhone"
          @handleReloadComponent="handleRenderComponent"
        />
      </ion-content>
    </ion-page>
  </ion-modal>
  <!-- Modal Delivery time -->
  <ion-modal mode="md" :is-open="isOpenModalDeliveryTime" @didDismiss="setOpenModalDeliveryTime(false)">
    <ion-page>
      <Header
        :title="customerDetailsEdit?.name || customerDetailsEdit?.branch"
        @handleCloseModal="setOpenModalDeliveryTime(false)"
      />
      <ion-content>
        <ModalEditDeliveryTime
          :workingHours="customerDetailsEdit?.working_hours"
          :defaultWorkingHours="defaultWorkingHours"
          @handleUpdateHour="handleUpdateHour"
        />
      </ion-content>
    </ion-page>
  </ion-modal>
  <!-- Modal Payment -->
  <ion-modal mode="md" :is-open="isOpenModalPayment" @didDismiss="setOpenModalPayment(false)">
    <ion-page>
      <Header
        :title="customerDetailsEdit?.name || customerDetailsEdit?.branch"
        @handleCloseModal="handleGetListCardWhenBackPayment"
      />
      <ion-content>
        <ModalPayment
          :customerDetailsEdit="customerDetailsEdit"
          @handleCloseModal="handleGetListCardWhenBackPayment"
        />
      </ion-content>
    </ion-page>
  </ion-modal>
  <!-- modal crop image -->
  <ion-modal :is-open="isOpenModalCrop" css-class="modal-upload-image" @didDismiss="setOpenModalCrop(false)">
    <crop-box
      :src="imageCrop"
      @close-modal="setOpenModalCrop(false)"
      @crop-image="handleSendImageAfterCrop"
    />
  </ion-modal>
  <ion-loading
    mode="ios"
    :is-open="isOpenRefLoading"
    cssClass="my-custom-class"
    :message="`${$t('please_wait')}. . .`"
  >
  </ion-loading>
</template>

<script>
import CropBox from '@/modules/shared/views/CropBox';

// import ModalAddress from './Modal/ModalAddress.vue';
import halal from '@/assets/images/b2b/home/halal.svg';
import { alertController } from '@ionic/vue';
import { addOutline } from 'ionicons/icons';
import CustomerDetailImage from './CustomerDetailImage.vue';
import Header from './HeaderModal.vue';
import ModalCustomerDetails from './Modal/ModalCustomerDetails.vue';
import ModalEditBusinessInfomation from './Modal/ModalEditBusinessInformation/index.vue';
import ModalPersonInCharge from './Modal/ModalPersonInCharge/index.vue';
import ModalUploadImage from './Modal/ModalUploadImage.vue';
// import ModalAddAddress from './Modal/ModalEditBusinessInformation/ModalAddAdress.vue';
import { apolloClient } from '@/main';
import {
  getCustomerDetailBuyer,
  getGroupsAndCountriesAndDays,
  hoursAdd
} from '@/modules/b2b/services/graphql';
import { getAddressFormat } from '@/modules/b2b/services/libs/helper';
import { getCurrentUser } from '@/services/shared/graphql';
import { Capacitor } from '@capacitor/core';
import { Keyboard } from '@capacitor/keyboard';
import { toastController } from '@ionic/vue';
import { ref } from 'vue';
import ModalEditDeliveryTime from './Modal/ModalEditDeliveryTime.vue';
import ModalPayment from './Modal/ModalPayment.vue';

export default {
  components: {
    CropBox,
    Header,
    CustomerDetailImage,
    ModalUploadImage,
    ModalCustomerDetails,
    ModalEditBusinessInfomation,
    ModalPersonInCharge,
    ModalEditDeliveryTime,
    ModalPayment
    // ModalAddAddress
  },
  emits: ['title'],
  setup() {
    const isOpenRef = ref(false);
    const setOpen = async (state) => (isOpenRef.value = state);
    const isOpenModalUpdateImage = ref(false);
    const setOpenModalUpdateImage = async (state) => (isOpenModalUpdateImage.value = state);
    const isOpenModalBusiness = ref(false);
    const setOpenModalBusiness = async (state) => (isOpenModalBusiness.value = state);
    const isOpenModalPersonInCharge = ref(false);
    const setOpenModalPersonInCharge = async (state) => (isOpenModalPersonInCharge.value = state);
    // const isOpenModalAddAddress = ref(false);
    // const setOpenModalAddAddress = async (state) => (isOpenModalAddAddress.value = state);
    const isOpenModalDeliveryTime = ref(false);
    const setOpenModalDeliveryTime = async (state) => (isOpenModalDeliveryTime.value = state);
    const isOpenModalPayment = ref(false);
    const setOpenModalPayment = async (state) => (isOpenModalPayment.value = state);
    const isOpenRefLoading = ref(false);
    const setOpenLoading = async (state) => (isOpenRefLoading.value = state);
    const isOpenModalCrop = ref(false);
    const setOpenModalCrop = async (state) => (isOpenModalCrop.value = state);
    const handleScroll = (event) => {
      if (event && Capacitor.isNativePlatform()) {
        Keyboard.hide();
      }
    };

    return {
      isOpenRef,
      setOpen,
      handleScroll,
      isOpenModalBusiness,
      setOpenModalBusiness,
      isOpenModalPersonInCharge,
      setOpenModalPersonInCharge,
      // isOpenModalAddAddress,
      // setOpenModalAddAddress,
      isOpenModalUpdateImage,
      setOpenModalUpdateImage,
      isOpenModalDeliveryTime,
      setOpenModalDeliveryTime,
      isOpenModalPayment,
      setOpenModalPayment,
      isOpenRefLoading,
      setOpenLoading,
      isOpenModalCrop,
      setOpenModalCrop,
      addOutline,
      halal,
      getAddressFormat
      // ModalAddAddress
    };
  },
  inject: ['$storage'],

  data() {
    return {
      viewEntered: false,
      customerId: Number(this.$route.params.id),
      customerDetails: [],
      asyncData: false,
      customerDetailsEdit: [],
      defaultWorkingHours: [],
      sortListShowPersonInCharge: [],
      isGetListCardWhenBackBtn: false,
      // check when user click modal branches
      idBranch: '',
      isBranch: false,
      //
      imageCrop: '',
      imageAfterCrop: '',
      mobilePhone: '',
      countryId: null,
      disableAddBranch: false,
      user: {}
    };
  },
  props: {
    detailsKey: {
      type: String,
      default: ''
    }
  },
  watch: {
    detailsKey: async function () {
      await this.handleGetCustomerDetailBuyer();
      await this.handleGetGroupsAndCountriesAndDays();
    }
  },
  async mounted() {
    this.setOpenLoading(true);
    this.user = await this.$storage.getUser();
    this.countryId = this.user.country.id;
    await this.handleGetCustomerDetailBuyer();
    await this.handleGetGroupsAndCountriesAndDays();
    await this.handleGetDataUser();
    this.viewEntered = true;
    this.setOpenLoading(false);

    // check to show add branch option
    const companyInreviewList = await this.$storage.getCompanyReviewList();
    this.disableAddBranch = companyInreviewList.length > 0;

    // go to company detail when user is b2c
    if (this.isB2cUser) {
      await this.handleOpenModal(this.customerDetails, false);
    }
  },
  computed: {
    isB2cUser() {
      return !!this.user?.is_b2c;
    }
  },
  methods: {
    handleAddBranch() {
      this.$router.push({
        path: `/b2b/customers/add`,
        query: {
          headId: this.customerId
        }
      });
    },
    handleSendListShowPersonInCharge(event) {
      this.sortListShowPersonInCharge = event;
    },
    async handleGetDataUser() {
      const { data } = await apolloClient.query({
        query: getCurrentUser
      });
      this.mobilePhone = data.me.mobile;
    },
    handleOpenModalPayment() {
      this.setOpenModalPayment(true);
    },
    handleGetListCardWhenBackPayment() {
      this.isGetListCardWhenBackBtn = !this.isGetListCardWhenBackBtn;
      this.setOpenModalPayment(false);
    },
    async handleUpdateHour(payload) {
      this.setOpenLoading(true);
      const customerId = this.customerDetailsEdit.id;
      payload = payload.map((item) => ({ ...item, customer_id: customerId }));
      const variables = {
        isDashboardPage: false,
        payload
      };
      try {
        await apolloClient.mutate({
          mutation: hoursAdd,
          variables: variables
        });
        this.setOpenLoading(false);
        await this.handleRenderComponent();
      } catch (error) {
        this.setOpenLoading(false);
        this.presentAlert(error);
      }
    },
    async handleRenderComponent(isUpdate) {
      await this.handleGetCustomerDetailBuyer();
      await this.openToast();
      // await this.setOpenModalAddAddress(false);
      await this.setOpenModalBusiness(isUpdate ? isUpdate : false);
      await this.setOpenModalDeliveryTime(false);
      await this.setOpenModalPersonInCharge(false);
      await this.setOpenModalUpdateImage(false);
    },
    async handleOpenModal(params, isBranch) {
      isBranch ? ((this.idBranch = params.id), (this.isBranch = true)) : (this.idBranch = false);
      this.customerDetailsEdit = params;
      this.setOpen(true);
    },
    handleSendImageAfterCrop(event) {
      this.imageAfterCrop = event;
      this.setOpenModalCrop(false);
    },
    handleSendImageCrop(image) {
      this.imageCrop = image;
      this.setOpenModalCrop(true);
    },
    // handleCloseModalUploadImage() {
    //   this.setOpenModalUpdateImage(false);
    //   this.openToast();
    // },
    async handleGetCustomerDetailBuyer() {
      try {
        const { data } = await apolloClient.mutate({
          mutation: getCustomerDetailBuyer,
          variables: {
            id: this.customerId
          }
        });
        this.customerDetails = data.getCustomerDetailBuyer; //data show first
        if (this.isBranch) {
          this.customerDetailsEdit = data.getCustomerDetailBuyer.branches.find(
            (item) => item.id === +this.idBranch
          );
        } else {
          this.customerDetailsEdit = data.getCustomerDetailBuyer; //data bind props
        }
        this.$emit('title', data.getCustomerDetailBuyer.name);
        this.asyncData = true;
      } catch (error) {
        this.presentAlert(error);
      }
    },
    async handleGetGroupsAndCountriesAndDays() {
      const { data } = await apolloClient.query({
        query: getGroupsAndCountriesAndDays,
        variables: {}
      });
      this.defaultWorkingHours = data.getGroupsAndCountriesAndDays.days;
    },
    async handleOpenModalPersonInCharge() {
      this.setOpenModalPersonInCharge(true);
      await this.handleGetCustomerDetailBuyer();
    },
    async presentAlert(message) {
      this.setOpenLoading(false);
      const alert = await alertController.create({
        cssClass: 'my-custom-class',
        header: 'Alert',
        message: message,
        buttons: [this.$t('OK')]
      });
      await alert.present();
    },
    async openToast() {
      const toast = await toastController.create({
        mode: 'ios',
        color: 'dark',
        position: 'top',
        message: this.$t('saved_successfully'),
        cssClass: 'toast-custom-class',
        duration: 1000
      });
      return toast.present();
    },
    dismissModal() {
      if (this.isB2cUser) {
        this.$router.push('/b2b/account');
      }
      this.setOpen(false);
    }
  }
};
</script>

<style src="./styles/CustomerDetail.scss" lang="scss" scoped></style>
